import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContextProvider";
import {
  leaveGroup,
  getMyGroup
} from "../../services/api.service";

import Table from "../../components/Table";
import ConfirmDialog from "../../components/ConfirmDialog";

const JoinGroups = () => {
  const { userDetail, onOpenAlert } = useAppContext();

  const [dataSource, setDataSource] = useState([]);
  const [selectItem, setSelectItem] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const columnsOwnGroup = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "group", title: "Group Name", render: (val) => val.name },
    { key: "group", title: "Administrator", render: (val) => val.user.email },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <div className="flex justify-center gap-2">
          <div
            className="p-1.5 text-red-500 text-xs rounded-lg bg-red-100 cursor-pointer hover:bg-red-500 hover:text-white flex justify-between items-center gap-2"
            onClick={() => {
              setOpenConfirm(true)
              setSelectItem(item);
            }}
          >
            <span>Keluar</span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    onGetList();
  }, []);

  const onGetList = async () => {
    try {
      setIsLoading(true);
      const response = await getMyGroup();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setDataSource(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetList: ", error);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await leaveGroup(selectItem?.group_id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  return (
    <>
      <div className="flex justify-between px-4 font-bold">
        <span>Join Group</span>
      </div>
      <div className="p-4">
        <Table
          loading={isLoading}
          columns={columnsOwnGroup}
          dataSource={dataSource}
        />
      </div>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Keluar Group"
        detail="Apakah anda yakin ingin keluar dari group ini?"
        confirmText="Keluar"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default JoinGroups;
