import axios from "axios";
import { destroyToken, getLocalToken, getSSOToken, removeLocalStorage } from "./jwt.service";

const validateUrl = (url) => {
  if (url && (url.includes("http://") || url.includes("https://"))) {
    return { baseURL: url, token: getSSOToken() ? `Bearer ${getSSOToken()}` : "" };
  }
  return { baseURL: `${process.env.REACT_APP_API_URL}/api/`, token: getLocalToken() ? `Bearer ${getLocalToken()}` : "" };
}

export const initializeAxios = () => {
  // Request interceptor
  axios.interceptors.request.use((config) => {
    config.baseURL = validateUrl(config.url).baseURL;
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Source: process.env.REACT_APP_URL,
      Name: "Kolab"
    };
    config.headers.Authorization = validateUrl(config.url).token;
    return config;
  }, (error) => Promise.reject(error));

  // Response interceptor
  axios.interceptors.response.use((response) => {
    return (response?.data?.data || response?.data?.message) ? response.data : response;
  }, (error) => {
    const message = error?.response?.data?.message || error?.response?.statusText || error?.message || error;
    if (message?.includes("Unauthenticated")) {
      destroyToken();
      removeLocalStorage("user");
      window.location.href = "/login";
    }
    return Promise.reject(message);
  });
}

export const userAvailabilitySSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/user-available`, { email });
}

export const registerSSO = async (requestData = {}) => {
  const headers = {
    ApiSource: process.env.REACT_APP_API_URL
  }
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/register_sso`, requestData, { headers: headers });
}

export const forgotPasswordSSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/forgot-password`, { email });
}

export const authRegister = async (requestData = {}) => {
  return await axios.post("auth/register", requestData);
}

export const authLogin = async (requestData = {}) => {
  return await axios.post("auth/login", requestData);
}

export const authChangePassword = async (requestData = {}) => {
  return await axios.put("auth/change-password", requestData);
}

export const authResetPassword = async (requestData = {}) => {
  return await axios.put("auth/reset-password", requestData);
}

export const getAnalytics = async () => {
  return await axios.get(`analytics`);
}

export const getUserList = async () => {
  return await axios.get("user/list");
}

export const updateUser = async (requestData = {}) => {
  return await axios.put("user/update", requestData);
}

export const getLicenseList = async () => {
  return await axios.get("user/license");
}

export const getModuleList = async () => {
  return await axios.get("user/modules");
}

export const getEmployeeList = async () => {
  return await axios.get("user/employee");
}

export const getEmployeeDetail = async (id) => {
  return await axios.get(`user/employee/detail/${id}`);
}

export const createUpdateEmployee = async (requestData = {}) => {
  return await axios.post("user/employee/create-update", requestData);
}

export const resetPasswordEmployee = async (email) => {
  return await axios.post(`user/employee/reset-password`, { email });
}

export const verifiedEmployee = async (requestData) => {
  return await axios.post(`user/employee/verified`, requestData);
}

export const deleteEmployee = async (id) => {
  return await axios.delete(`user/employee/delete/${id}`);
}

// API Todos
export const getTodoList = async () => {
  return await axios.get(`todo/list`);
}

export const deleteTodo = async (id) => {
  return await axios.delete(`todo/${id}`);
}

export const upsertTodo = async(requestData) => {
  return await axios.post(`todo/upsert`, requestData);
}

// API Notes
export const getNoteList = async () => {
  return await axios.get(`notes/list`);
}

export const deleteNote = async (id) => {
  return await axios.delete(`notes/${id}`);
}

export const upsertNote = async(requestData) => {
  return await axios.post(`notes/upsert`, requestData);
}

// API Team
export const getGroups = async () => {
  return await axios.get(`group/list`);
}

export const getMyGroup = async () => {
  return await axios.get(`group/groups`);
}

export const upsertGroup = async(requestData) => {
  return await axios.post(`group/upsert`, requestData);
}

export const deleteGroup = async (id) => {
  return await axios.delete(`group/${id}`);
}

export const inviteGroup = async(requestData) => {
  return await axios.post(`group/invite`, requestData);
}

export const kickGroup = async(requestData) => {
  return await axios.post(`group/kick`, requestData);
}

export const leaveGroup = async(ID) => {
  return await axios.get(`group/leave/${ID}`)
}

export const approveGroup = async (requestData) => {
  return await axios.post(`group/approve`, requestData);
}

export const declineGroup = async (requestData) => {
  return await axios.post(`group/decline`, requestData);
}

// API Notification
export const getNotification = async () => {
  return await axios.get(`notification/list`);
}

export const readNotification = async (id) => {
  return await axios.get(`notification/read/${id}`)
}

// API Event
export const getEventList = async () => {
  return await axios.get(`event/list`);
}

export const deleteEvent = async (id) => {
  return await axios.delete(`event/${id}`);
}

export const upsertEvent = async(requestData) => {
  return await axios.post(`event/upsert`, requestData);
}

// API Projects
export const getProjectList = async () => {
  return await axios.get(`project/list`);
}

export const deleteProject = async (id) => {
  return await axios.delete(`project/${id}`);
}

export const upsertProject = async(requestData) => {
  return await axios.post(`project/upsert`, requestData);
}

export const getMembersProject = async (id) => {
  return await axios.get(`project/${id}/members`);
}

// API Tasks
export const getMyTasks = async () => {
  return await axios.get(`task`);
}