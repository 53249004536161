import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  FaPeopleCarryBox,
  FaPen,
  FaPlus,
  FaDiagramProject,
  FaTrashCan,
} from "react-icons/fa6";

import { useAppContext } from "../../context/AppContextProvider";
import {
  upsertProject,
  deleteProject,
  getProjectList,
  getGroups,
} from "../../services/api.service";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import ConfirmDialog from "../../components/ConfirmDialog";
import Select from "../../components/Select";
import BlockNoteEditorPersonal from "../../components/BlockNoteEditorPersonal";
import { LoadingIcon } from "../../assets/icon/SvgIconComponents";

const ManageProjects = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const [dataSource, setDataSource] = useState([]);

  const [selectItem, setSelectItem] = useState({});

  const [openForm, setOpenForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    onGetGroups();
    onGetProjectList();
  }, []);

  const onGetProjectList = async () => {
    try {
      setIsLoading(true);
      const response = await getProjectList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setDataSource(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetProjectList: ", error);
    }
  };

  const onCreateUpdateProject = async (data) => {
    try {
      setIsSubmitLoading(true);

      const request = {
        id: selectItem?.id,
        title: data?.title || selectItem?.title,
        description: data?.description || selectItem?.description,
        group_id: data?.group?.value || selectItem?.group_id,
      };

      console.log("request", request);
      const response = await upsertProject(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetProjectList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteProject(selectItem?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetProjectList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onGetGroups = async () => {
    try {
      const response = await getGroups();
      if (!response.success) throw response.message;
      setGroupOptions(response?.data);
    } catch (error) {
      console.log("Error on onGetList: ", error);
    }
  };

  const onOpenForm = () => {
    if (dataSource?.length >= userDetail?.max_projects) {
      onOpenAlert("error", "Max project limit reached");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setSelectItem({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Project List</span>
          <Button
            label="Add"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          {isLoading && (
            <div className="text-sky-600 flex items-center justify-center gap-4 ">
              <LoadingIcon className="w-8 h-8 mr-2" /> Loading...
            </div>
          )}
          {!isLoading &&
            dataSource.map((data) => (
              <div className="p-4 my-2 border rounded" key={`project-${data.id}`}>
                <div className="flex items-center justify-between">
                  <Link
                    to={`/projects/${data.id}`}
                    className="flex items-center gap-4 hover:cursor-pointer"
                  >
                    <FaDiagramProject className="text-sky-600" />
                    <p className="font-medium">
                      <span className="underline">{data.title}</span> -{" "}
                      {data.task_count}
                    </p>
                  </Link>

                  {data.user_id === userDetail.userId && (
                    <div className="flex justify-center gap-2">
                      <>
                        <Tooltip
                          place="top"
                          anchorSelect="#edit-data"
                          content="Edit Data"
                        />
                        <div
                          id="edit-data"
                          className="p-1.5 text-amber-500 text-xs rounded-lg bg-amber-100 cursor-pointer hover:bg-amber-500 hover:text-white"
                          onClick={() => {
                            setOpenForm(true);
                            setSelectItem(data);
                            onChange("title", data?.title);
                            onChange("description", data?.description);
                            onChange("group", data?.group?.name);
                          }}
                        >
                          <FaPen />
                        </div>
                      </>
                      <>
                        <Tooltip
                          place="top"
                          anchorSelect="#delete-data"
                          content="Delete Data"
                        />
                        <div
                          id="delete-data"
                          className="p-1.5 text-red-500 text-xs rounded-lg bg-red-100 cursor-pointer hover:bg-red-500 hover:text-white"
                          onClick={() => {
                            setOpenConfirm(true);
                            setSelectItem(data);
                          }}
                        >
                          <FaTrashCan />
                        </div>
                      </>
                    </div>
                  )}
                </div>
                <table className="w-full">
                  <thead className="text-sm font-light text-gray-900">
                    <tr className="text-sm font-thin">
                      <th scope="col" className="px-2 py-2">
                        Task
                      </th>
                      <th scope="col" className="px-2 py-2">
                        Assignee
                      </th>
                      <th scope="col" className="px-2 py-2">
                        Priority
                      </th>
                      <th scope="col" className="px-2 py-2">
                        Tags
                      </th>
                      <th scope="col" className="px-2 py-2">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-xs text-gray-900">
                    {data?.tasks?.length <= 0 && (
                      <tr className="ring-1 ring-gray-500">
                        <td
                          className="px-6 py-2 font-medium text-center w-max"
                          colSpan={5}
                        >
                          Data task Not Found
                        </td>
                      </tr>
                    )}

                    {data?.tasks?.length > 0 &&
                      data.tasks.map((task) => (
                        <tr
                          className="hover:bg-gray-100 border-t border-b"
                          key={`column-${task.id}`}
                        >
                          <td className="px-2 py-3 text-center whitespace-nowrap">
                            {task?.title}
                          </td>
                          <td className="px-2 py-3 text-center whitespace-nowrap">
                            {task?.title}
                          </td>
                          <td className="px-2 py-3 text-center whitespace-nowrap">
                            {task?.priority}
                          </td>
                          <td className="px-2 py-3 text-center whitespace-nowrap">
                            {task?.tag}
                          </td>
                          <td className="px-2 py-3 text-center whitespace-nowrap">
                            {task?.status}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      </div>
      <Modal
        open={openForm}
        onOpen={setOpenForm}
        className={{ container: "p-0 max-w-3xl" }}
      >
        <form onSubmit={handleSubmit(onCreateUpdateProject)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {selectItem?.id ? <FaPen /> : <FaPeopleCarryBox />}
            </div>
            <div className="ml-3 mr-1 text-lg">
              {selectItem?.id ? "Edit" : "Add"}
            </div>
            {selectItem?.id && (
              <h1 className="mx-1 text-sm">
                (&nbsp;{selectItem?.title}&nbsp;)
              </h1>
            )}
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Title"
                placeholder="Masukkan title"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.title?.message}
                onInputChange={(e) => onChange("title", e.target.value)}
                {...register("title", { required: "title wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <BlockNoteEditorPersonal
                label="Description"
                className={{
                  label: "text-gray-700",
                }}
                errorMessage={errors?.description?.message}
                onInputChange={(e) => onChange("description", e)}
                value={selectItem?.description}
              />
            </div>
            <div className="mb-5">
              <Select
                label="Group"
                placeholder="Pilih Group"
                options={groupOptions}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize",
                }}
                errorMessage={errors?.group?.message}
                onInputChange={(e) => {
                  onChange("group", e.name);
                  onChange("group.value", e.id);
                }}
                {...register("group")}
              />
            </div>

            <Button
              label="submit"
              isLoading={isSubmitLoading}
              className="w-1/3 h-10 mt-8 ml-auto"
            />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

export default ManageProjects;
