import { cn } from "../../utils/helper";

export const LoadingIcon = ({ className }) => {
  return (
    <svg className={cn("w-5 h-5 animate-spin", className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
}

export const SuccessIcon = ({ className }) => {
  return (
    <svg className={cn("w-4 h-4", className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="6" fill="url(#paint0_linear_6_55)" />
      <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_6_55" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#48CA93" />
          <stop offset="1" stopColor="#48BACA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const ErrorIcon = ({ className }) => {
  return (
    <svg className={cn("w-4 h-4", className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="6" fill="url(#paint0_linear_6_67)" />
      <path d="M15 9.00002L9 15M8.99997 9L14.9999 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_6_67" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E88B76" />
          <stop offset="1" stopColor="#CA5048" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const BugIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#E5493A"
              rx="2"
            ></rect>
            <path fill="#FFF" d="M10 7a3 3 0 11-6 0 3 3 0 016 0"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export const TaskIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#4BADE8"
              rx="2"
            ></rect>
            <g
              stroke="#FFF"
              strokeLinecap="round"
              strokeWidth="2"
              transform="translate(4 4.5)"
            >
              <path d="M2 5l4-5"></path>
              <path d="M2 5L0 3"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export const ChangeRequestIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#F79232"
              rx="2"
            ></rect>
            <g fill="#FFF" transform="translate(6 3)">
              <path d="M2 4V1a1 1 0 10-2 0v3a1 1 0 102 0zM1 6a1 1 0 100 2 1 1 0 000-2z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}