import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { initializeAxios } from "../services/api.service";
// Import Middleware
import AuthenticateRoute from "./AuthenticateRoute";

// Import Authentication
import Login from "../pages/authenticate/Login";
import Register from "../pages/authenticate/Register";
import ForgotPassword from "../pages/authenticate/ForgotPassword";

// import Layout
import Main from "../layout/Main";

// Import Super Admin Component
import AdminDashboard from "../pages/admin/Dashboard";
import ManageUsers from "../pages/admin/ManageUsers";

// Import Dashboard
import Dashboard from "../pages/Dashboard";

import ManageTodo from "../pages/todo/ManageTodo";
import ManageNotes from "../pages/notes/ManageNotes";
import ManageTeams from "../pages/teams/ManageTeams";
import ManageCalendars from "../pages/calendars/ManageCalendars";
import ManageProject from "../pages/projects/ManageProject";
import KanbanProject from "../pages/projects/KanbanProject";

const RoutesManager = () => {
  initializeAxios();

  return (
    <Routes>
      {/* Router should be login */}
      <Route path="/" element={<AuthenticateRoute><Main /></AuthenticateRoute>}>
        <Route index element={<Navigate to="dashboard" />} />
        <Route index path="dashboard" element={<Dashboard />} />
        
        {/* Router Notes */}
        <Route path="notes" element={<ManageNotes />} />
        
        {/* Router Todo */}
        <Route path="todo" element={<ManageTodo />} />

        {/* Router Team */}
        <Route path="teams" element={<ManageTeams />} />

        {/* Router Calendar */}
        <Route path="calendars" element={<ManageCalendars />} />
        
        {/* Router Project */}
        <Route path="projects" element={<Outlet />}>
          <Route index element={<ManageProject />} />
          <Route path=":id" element={<KanbanProject />} />
        </Route>

        {/* Router Super Admin */}
        <Route path="/admin" element={<Outlet />}>
          <Route index path="dashboard" element={<AdminDashboard />} />
          <Route path="users" element={<ManageUsers />} />
        </Route>
      </Route>

      {/* Router Public */}
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="password-reset/:token" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="login" replace />} />
    </Routes>
  );
};

export default RoutesManager;
