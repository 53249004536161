import { Outlet } from 'react-router-dom';
import { useAppContext } from '../context/AppContextProvider';
import Sider from "./Sider";
import Header from "./Header";
import AlertComponent from '../components/Alert';

const Main = () => {
  const { alertOption, onCloseAlert } = useAppContext();

  return (
    <div id="layout" className="flex h-screen bg-slate-200">
      <Sider />
      <Header>
        <Outlet />
      </Header>
       {alertOption && (
        <AlertComponent
          type={alertOption.type}
          open={alertOption.open}
          message={alertOption.message}
          onClose={onCloseAlert}
        />
      )}
    </div>
  )
}

export default Main;