import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { FaEnvelope } from "react-icons/fa6";
import { Transition } from "@headlessui/react";
import {
  getNotification,
  readNotification,
  approveGroup,
  declineGroup,
} from "../services/api.service";

import { LoadingIcon } from "./../assets/icon/SvgIconComponents";

const INTERVAL_FETCH = 50000;

const Notification = React.memo(() => {
  const notificationDropdownRef = useRef();
  const intervalRef = useRef();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [notification, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onGetList();

    document.addEventListener("mousedown", onClickOutside);
    return () => {
      clearInterval(intervalRef.current);
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  const onGetList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getNotification();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setNotification(response?.data);

      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(onGetList, INTERVAL_FETCH);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetList: ", error);
    }
  }, []);

  const onReadNotification = useCallback(
    async (item) => {
      try {
        await readNotification(item.id);
        return onGetList();
      } catch (error) {
        console.log("Error on onReadNotification: ", error);
      }
    },
    [onGetList]
  );

  const onClickInvitationGroup = useCallback(
    async (item, type) => {
      try {
        const itemData = JSON.parse(item?.data);
        const payload = {
          groupID: itemData.group_id,
          notificationID: item.id,
        };

        if (type == "approve") {
          const response = await approveGroup(payload);
          if (!response.success) throw response.message;
          return onGetList();
        }

        const response = await declineGroup(payload);
        if (!response.success) throw response.message;
        return onGetList();
      } catch (error) {
        console.log("Error on onClickInvitationGroup: ", error);
      }
    },
    [onGetList]
  );

  const renderAction = (item) => {
    if (item.is_read) {
      return;
    }

    if (item.type === "INVITATION_GROUP") {
      return (
        <div className="flex justify-between gap-2">
          <span
            className="text-green-300 font-semibold cursor-pointer hover:underline"
            onClick={() => onClickInvitationGroup(item, "approve")}
          >
            Accept
          </span>
          <span
            className="text-red-300 font-semibold cursor-pointer hover:underline"
            onClick={() => onClickInvitationGroup(item, "decline")}
          >
            Decline
          </span>
        </div>
      );
    }

    return (
      <span
        onClick={() => onReadNotification(item)}
        className="text-blue-300 font-semibold cursor-pointer hover:underline"
      >
        Read
      </span>
    );
  };

  const renderDropdown = useCallback(() => {
    if (isLoading) {
      return (
        <span className="flex items-center justify-center">
          <LoadingIcon className="w-4 h-4 mr-2" /> Loading...
        </span>
      );
    }

    if (!notification.length) {
      return (
        <span className="flex items-center justify-center text-sky-700 font-medium">
          Notification Not Found
        </span>
      );
    }

    return notification.map((item, index) => (
      <div
        key={index}
        className="flex items-center justify-between gap-2 p-2 rounded-lg hover:bg-gray-100"
      >
        <span dangerouslySetInnerHTML={{ __html: item.content }} />
        {renderAction(item)}
      </div>
    ));
  }, [isLoading, notification, renderAction]);

  const onClickOutside = (e) => {
    if (notificationDropdownRef.current?.contains(e.target)) return;
    setOpenDropdown(false);
  };

  const onClickNotification = () => {
    if (openDropdown == false) {
      onGetList();
    }
    setOpenDropdown(!openDropdown);
  };

  const countUnReadNotification = notification.filter((n) => !n.is_read).length;

  return (
    <div ref={notificationDropdownRef}>
      <button
        type="button"
        className="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-800 "
        onClick={onClickNotification}
      >
        <FaEnvelope />
        <span className="sr-only">Notifications</span>
        <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2">
          {countUnReadNotification}
        </div>
      </button>
      <Transition
        as={Fragment}
        show={openDropdown}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute right-0 top-12 w-[700px] min-h-10 p-2 text-base bg-white rounded-lg shadow-lg ring-1 ring-gray-300">
          {renderDropdown()}
        </div>
      </Transition>
    </div>
  );
});

export default Notification;
