import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPeopleRoof, FaPen, FaPlus } from "react-icons/fa6";

import { useAppContext } from "../../context/AppContextProvider";
import {
  upsertGroup,
  inviteGroup,
  deleteGroup,
  getGroups,
  kickGroup,
} from "../../services/api.service";

import Table from "../../components/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import ConfirmDialog from "../../components/ConfirmDialog";
import { cn } from "../../utils/helper";

const OwnGroups = () => {
  const { userDetail, onOpenAlert } = useAppContext();

  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const [dataSource, setDataSource] = useState([]);
  const [selectItem, setSelectItem] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const columnsOwnGroup = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Group Name" },
    {
      key: "members",
      title: "Member",
      render: (val) => `${val.length} Person`,
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <div className="flex justify-center gap-2">
          <div
            className="p-1.5 text-amber-500 text-xs rounded-lg bg-amber-100 cursor-pointer hover:bg-amber-500 hover:text-white flex justify-between items-center gap-2"
            onClick={() => {
              setOpenForm(true);
              setSelectItem(item);
              onChange("name", item?.name);
            }}
          >
            <FaPen />
            <span>Manage Group</span>
          </div>
        </div>
      ),
    },
  ];

  const columnMember = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "user", title: "Name", render: (val) => val?.name },
    { key: "user", title: "Email", render: (val) => val?.email },
    {
      key: "status",
      title: "Status",
      render: (val) => {
        return (
          <div
            className={cn(
              "uppercase px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
              {
                "text-yellow-500 bg-yellow-600/5": val === "PENDING",
                "text-green-500 bg-green-600/5": val === "APPROVE",
              }
            )}
          >
            {val}
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <div className="flex justify-center gap-2">
          {!item.is_owner && (
            <div
              className="p-1.5 text-red-500 text-xs rounded-lg bg-red-100 cursor-pointer hover:bg-red-500 hover:text-white flex justify-between items-center gap-2"
              onClick={() => {
                onKickGroup(item.user_id);
              }}
            >
              <span>Hapus</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    onGetList();
  }, []);

  const onGetList = async () => {
    try {
      setIsLoading(true);
      const response = await getGroups();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setDataSource(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetList: ", error);
    }
  };

  const onUpsert = async (data) => {
    try {
      setIsSubmitLoading(true);

      const request = {
        id: selectItem?.id,
        name: data?.name || selectItem?.name,
      };

      console.log("request", request);
      const response = await upsertGroup(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteGroup(selectItem?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onOpenForm = () => {
    if (dataSource?.length >= userDetail?.max_team) {
      onOpenAlert("error", "Max group limit reached");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setSelectItem({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  const onInviteMember = async () => {
    try {
      if (!email) return;

      setIsSubmitLoading(true);

      const request = {
        email: email,
        group_id: selectItem?.id,
      };

      const response = await inviteGroup(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onKickGroup = async (userID) => {
    try {
      setIsSubmitLoading(true);

      const request = {
        remove_user_id: userID,
        group_id: selectItem?.id,
      };

      const response = await kickGroup(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  return (
    <>
      <div className="flex justify-between px-4 font-bold">
        <span>Own Group</span>
        <Button
          label="Add"
          icon={<FaPlus />}
          className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
          onClickBtn={onOpenForm}
        />
      </div>
      <div className="p-4">
        <Table
          loading={isLoading}
          columns={columnsOwnGroup}
          dataSource={dataSource}
        />
      </div>
      <Modal
        open={openForm}
        onOpen={setOpenForm}
        className={{ container: "p-0 max-w-3xl" }}
      >
        <form onSubmit={handleSubmit(onUpsert)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {selectItem?.id ? <FaPen /> : <FaPeopleRoof />}
            </div>
            <div className="ml-3 mr-1 text-lg">
              {selectItem?.id ? "Edit" : "Add"}
            </div>
            {selectItem?.id && (
              <h1 className="mx-1 text-sm">(&nbsp;{selectItem?.name}&nbsp;)</h1>
            )}
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Group Name"
                placeholder="Masukkan group name"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.name?.message}
                onInputChange={(e) => onChange("name", e.target.value)}
                {...register("name", { required: "group name wajib diisi!" })}
              />
            </div>

            {selectItem?.id && (
              <>
                <div className="mb-5">
                  <Input
                    label="Email"
                    placeholder="Masukkan email"
                    className={{
                      label: "text-gray-700",
                      input: "h-10 pl-3",
                    }}
                    onInputChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className={cn(
                      "w-1/5 h-10 mt-8 ml-auto flex items-center justify-center gap-2 rounded-lg capitalize bg-sky-600 hover:bg-sky-600/75 border-none text-sm text-white font-semibold outline-none transition active:focus:scale-95 hover:cursor-pointer",
                      {
                        "bg-slate-400 hover:bg-slate-400 text-black hover:cursor-not-allowed":
                          email === "",
                      }
                    )}
                    onClick={onInviteMember}
                  >
                    Invite
                  </div>
                </div>

                <div className="mb-5">
                  <Table
                    loading={isLoading}
                    columns={columnMember}
                    dataSource={selectItem?.members}
                  />
                </div>
              </>
            )}

            <div className="flex justify-between gap-2">
              {selectItem?.id && (
                <div
                  className="w-2/12 h-10 mt-8 p-1.5 text-red-500 text-xs rounded-lg bg-red-100 cursor-pointer hover:bg-red-500 hover:text-white flex justify-around items-center gap-2"
                  onClick={() => {
                    setOpenConfirm(true);
                  }}
                >
                  <span>Delete</span>
                </div>
              )}
              <Button
                label="submit"
                isLoading={isSubmitLoading}
                className="w-1/3 h-10 mt-8 ml-auto"
              />
            </div>
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default OwnGroups;
