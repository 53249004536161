import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { FaPeopleCarryBox, FaPen, FaPlus } from "react-icons/fa6";

import { useAppContext } from "../../context/AppContextProvider";
import {
  upsertTodo,
  deleteTodo,
  getTodoList,
} from "../../services/api.service";
import { calendarFormat, cn } from "../../utils/helper";

import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Select from "../../components/Select";
import InputDate from "../../components/InputDate";
import ConfirmDialog from "../../components/ConfirmDialog";

import TodoStatus from "./../../assets/json/todo-status.json";

const ManageTodo = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const dueDateRef = useRef(undefined);

  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectItem, setSelectItem] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const renderInitials = (name) => {
    return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase();
  }

  const columns = [
    {
      key: "status",
      title: "Status",
      render: (val) => {
        return (
          <div
            className={cn(
              "uppercase px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
              {
                "text-yellow-500 bg-yellow-600/5": val === "todo",
                "text-green-500 bg-green-600/5": val === "done",
              }
            )}
          >
            {val}
          </div>
        );
      },
    },
    { key: "task", title: "Task name" },
    {
      key: "due_date",
      title: "Due Date",
      render: (val) => calendarFormat(val),
    },
    {
      key: "task",
      title: "Assignee",
      render: (val, item) => (
        <div className="flex gap-1 items-center">
          <div className="flex items-center justify-center text-white bg-gray-400 rounded-full w-6 h-6">
            {renderInitials(item?.user?.name)}
          </div>
          {item?.user?.name}
        </div>
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          hiddenPrintBarcode
          onEdit={() => {
            setOpenForm(true);
            setSelectItem(item);
            onChange("task", item?.task);
            onChange("due_date", item?.due_date);
            onChange("status", item?.status);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setSelectItem(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetTodoList();
  }, []);

  const onGetTodoList = async () => {
    try {
      setIsLoading(true);
      const response = await getTodoList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setDataSource(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetTodoList: ", error);
    }
  };

  const onCreateUpdateTodo = async (data) => {
    try {
      setIsSubmitLoading(true);
      const dueDate = dueDateRef?.current?.onValidateReturnValue();
      if (!dueDate) return;

      const request = {
        id: selectItem?.id,
        task: data?.task || selectItem?.task,
        due_date: new Date(dueDate) || selectItem?.due_date,
        status: data?.status || selectItem?.status,
      };
      const response = await upsertTodo(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetTodoList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteTodo(selectItem?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetTodoList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onOpenForm = () => {
    reset();
    clearErrors();
    setOpenForm(true);
    setSelectItem({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Todo List</span>
          <Button
            label="Add"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={dataSource}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={currentPage}
            total={!isLoading ? dataSource?.length : 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Modal
        open={openForm}
        onOpen={setOpenForm}
        className={{ container: "p-0" }}
      >
        <form onSubmit={handleSubmit(onCreateUpdateTodo)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {selectItem?.id ? <FaPen /> : <FaPeopleCarryBox />}
            </div>
            <div className="ml-3 mr-1 text-lg">
              {selectItem?.id ? "Edit" : "Add"}
            </div>
            {selectItem?.id && (
              <h1 className="mx-1 text-sm">(&nbsp;{selectItem?.task}&nbsp;)</h1>
            )}
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Task"
                placeholder="Masukkan task"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.task?.message}
                onInputChange={(e) => onChange("task", e.target.value)}
                {...register("task", { required: "task wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <InputDate
                ref={dueDateRef}
                label="Pilih Tanggal"
                minDate={new Date()}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
              />
            </div>
            <div className="mb-5">
              <Select
                label="Status"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  option: "capitalize",
                }}
                placeholder="Pilih Status"
                options={TodoStatus}
                errorMessage={errors?.status?.message}
                onInputChange={(e) => onChange("status", e.name)}
                {...register("status", { required: "Status wajib diisi!" })}
              />
            </div>
            <Button
              label="submit"
              isLoading={isSubmitLoading}
              className="w-1/3 h-10 mt-8 ml-auto"
            />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

export default ManageTodo;
