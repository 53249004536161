import React, { forwardRef, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import Datepicker from "react-tailwindcss-datepicker";
import { cn } from '../utils/helper';

const InputDate = forwardRef(({
  label,
  placeholder = "Select Date",
  dateFormat = "DD MMMM YYYY",
  className,
  value = moment(new Date()).format('YYYY-MM-DD'),
  maxDate,
  minDate,
  errorMessage = "Input Date is required",
  onChange
}, ref) => {
  const [selectedDate, setSelectedDate] = useState({ startDate: value, endDate: value });
  const [showError, setShowError] = useState(false);

  useImperativeHandle(ref, () => ({
    onValidateReturnValue: () => {
      if (!selectedDate?.startDate) {
        setShowError(true);
        return;
      }
      return selectedDate?.startDate;
    }
  }))

  const onChangeValue = (date) => {
    setShowError(false);
    setSelectedDate(date);
    onChange?.(date?.startDate);
  }

  return (
    <div ref={ref} className={cn("w-full", className?.container)}>
      {label && <div className={cn("mb-1 text-sm text-gray-700", className?.label)}>{label}</div>}
      <Datepicker
        asSingle
        readOnly
        maxDate={maxDate}
        minDate={minDate}
        placeholder={placeholder}
        containerClassName="relative outline-none"
        inputClassName={cn(
          `relative transition-all duration-300 py-2.5 pl-4 pr-8 w-full ring-1 ring-inset ring-gray-300 rounded-lg text-sm
          placeholder-gray-400 bg-white disabled:opacity-40 disabled:cursor-not-allowed outline-none focus:ring-gray-500`,
          className?.input
        )}
        toggleClassName="absolute rounded-r-lg right-2.5 h-full text-sm text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
        displayFormat={dateFormat}
        useRange={false}
        value={selectedDate}
        onChange={onChangeValue}
      />
      {showError && <p className="mt-1 -mb-5 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
});

export default InputDate;