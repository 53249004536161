import React, { useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

import KanbanBoard from "../../components/KanbanBoard";
import Button from "../../components/Button";

import { removeLabel } from "../../utils/syncfusion";

const KanbanProject = () => {
  const kanbanRef = useRef(null);
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const projectID = pathSegments[pathSegments.length - 1];

  const onOpenForm = () => {
    if (kanbanRef.current) {
      kanbanRef.current.handleAddCard();
    }
  };

  useEffect(() => {
    removeLabel();
  }, []);

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between items-center p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Projects</span>
          <Button
            label="Add"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="m-5 h-[800px]">
          <KanbanBoard ref={kanbanRef} projectID={projectID} />
        </div>
      </div>
    </div>
  );
};
export default KanbanProject;
