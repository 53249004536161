import React, { forwardRef, useEffect, useState } from "react";
import { extend } from "@syncfusion/ej2-base";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { getMembersProject } from "../services/api.service";

import { LoadingIcon } from "../assets/icon/SvgIconComponents";

const KanbanForm = forwardRef((props, ref) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    onGetMembers();
  }, []);

  const onGetMembers = async () => {
    try {
      if (!props?.projectID) {
        throw new Error("Project ID not found");
      }

      const response = await getMembersProject(props.projectID);
      if (!response.success) throw response.message;

      setMembers(response?.data);
    } catch (error) {
      console.log("Error on onGetMembers: ", error);
    }
  };

  const mappingUserFields = { text: "name", value: "id" };

  const statusData = [
    { label: "TO DO", value: "Open" },
    { label: "In Progress", value: "InProgress" },
    { label: "Testing", value: "Testing" },
    { label: "Done", value: "Close" },
  ];

  const statusPriority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const mappingFields = { text: "label", value: "value" };
  const [data, setData] = useState(extend({}, {}, props.payload, true));

  const isOwner =
    data?.project && props?.userDetail?.allGroups
      ? props?.userDetail?.allGroups?.find(
          (g) => g.group_id === data.project.group_id
        )
      : true;

  const onChange = (args) => {
    if (args?.target?.name) {
      setData({ ...data, [args?.target?.name]: args?.target?.value || "" });
    } else if (args?.itemData?.id) {
      setData({ ...data, assignment: args?.itemData?.id });
    }
  };

  if (!members.length) {
    return (
      <div className="flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Task</div>
        <input
          id="title"
          name="title"
          type="input"
          className="block w-full py-1.5 pr-1.5 text-sm text-gray-500 border-0 rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-[#7D818B] h-10 pl-3 e-field"
          placeholder={"Masukkan Task"}
          value={data?.title}
          onChange={onChange}
          readOnly={!isOwner}
          required
        />
        {!data?.title && (<p className="mt-1 text-sm text-red-600">Please fill the task</p>) }
      </div>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Description</div>
        <textarea
          name="description"
          className="block w-full py-1.5 pr-1.5 text-sm text-gray-500 border-0 rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-[#7D818B] h-20 pl-3 e-field"
          value={data?.description}
          onChange={onChange}
          readOnly={!isOwner}
        ></textarea>
        {!data?.description && (<p className="mt-1 text-sm text-red-600">Please fill the description</p>) }
      </div>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Status</div>
        <DropDownListComponent
          id="status"
          name="status"
          dataSource={statusData}
          fields={mappingFields}
          className="e-field"
          placeholder="Status"
          value={data?.status}
          readOnly={!isOwner}
        />
      </div>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Priority</div>
        <DropDownListComponent
          id="priority"
          name="priority"
          dataSource={statusPriority}
          fields={mappingFields}
          className="e-field"
          placeholder="priority"
          value={data?.priority}
          readOnly={!isOwner}
        />
      </div>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Tag</div>
        <input
          id="tag"
          name="tag"
          type="input"
          className="block w-full py-1.5 pr-1.5 text-sm text-gray-500 border-0 rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-[#7D818B] h-10 pl-3 e-field"
          placeholder={"Masukkan tag"}
          value={data?.tag}
          onChange={onChange}
          readOnly={!isOwner}
        />
        {!data?.tag && (<p className="mt-1 text-sm text-red-600">Please fill the tag</p>) }
      </div>
      <div className="mb-5">
        <div className={"mb-1 text-sm text-gray-700"}>Assignee</div>
        <DropDownListComponent
          id="user_id"
          name="user_id"
          className="e-field"
          dataSource={members}
          fields={mappingUserFields}
          placeholder="Assignee"
          value={data?.assignment}
          readOnly={!isOwner}
          change={onChange}
        />
        {!data?.assignment && (<p className="mt-1 text-sm text-red-600">Please select assignment</p>) }
      </div>
    </>
  );
});

export default KanbanForm;
