
import { useState } from "react";
import { FaArrowRotateRight } from "react-icons/fa6";


import OwnGroups from "./OwnGroups";
import JoinGroups from "./JoinGroups";

const ManageTeams = () => {
  const [reloadKey, setReloadKey] = useState(0);

  const handleReload = () => {
    setReloadKey(prevKey => prevKey + 1);
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between items-center p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Page Teams</span>
          <FaArrowRotateRight onClick={handleReload} className="cursor-pointer p-1 mx-1 w-7 h-7 text-sky-700 hover:rounded-md hover:bg-sky-600 hover:text-white"/>
        </div>
        <div className="my-4">
          <OwnGroups key={reloadKey}/>
        </div>
        <div className="my-4">
          <JoinGroups key={reloadKey}/>
        </div>
      </div>
    </div>
  );
};

export default ManageTeams;
