import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCalendarDay, FaArrowUpRightFromSquare } from "react-icons/fa6";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import idLocale from "@fullcalendar/core/locales/id";

import { LoadingIcon } from "../assets/icon/SvgIconComponents";

import Table from "../components/Table";
import Pagination from "../components/Pagination";

import { useAppContext } from "../context/AppContextProvider";
import { getEventList, getTodoList, getMyTasks } from "../services/api.service";

import { cn } from "../utils/helper";

const Dashboard = () => {
  // const navigate = useNavigate();
  const { userDetail } = useAppContext();

  // Events
  const [isLoadingAgenda, setIsLoadingAgenda] = useState(false);
  const [events, setEvents] = useState([]);

  // Todo
  const [isLoadingTodo, setIsLoadingTodo] = useState(false);
  const [currentPageTodo, setCurrentPageTodo] = useState(1);
  const [todos, setTodos] = useState([]);
  const columnsTodo = [
    { key: "task", title: "Task name" },
    {
      key: "status",
      title: "Status",
      render: (val) => {
        return (
          <div
            className={cn(
              "uppercase px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
              {
                "text-yellow-500 bg-yellow-600/5": val === "todo",
                "text-green-500 bg-green-600/5": val === "done",
              }
            )}
          >
            {val}
          </div>
        );
      },
    },
  ];

  // Task
  const [isLoadingTask, setLoadingTask] = useState(false);
  const [currentPageTask, setCurrentPageTask] = useState(1);
  const [tasks, setTasks] = useState([]);
  const columnsTask = [
    { key: "title", title: "Title" },
    { key: "priority", title: "Priority" },
    { key: "tag", title: "Tags" },
    {
      key: "status",
      title: "Status",
      render: (val) => {
        return (
          <div
            className={cn(
              "uppercase px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
              {
                "text-gray-500 bg-gray-600/5": val === "Open",
                "text-orange-500 bg-orange-600/5": val === "InProgress",
                "text-green-500 bg-green-600/5": val === "Testing",
                "text-sky-500 bg-sky-600/5": val === "Close",
              }
            )}
          >
            {val}
          </div>
        );
      },
    },
  ];

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <div className="flex gap-1 items-center px-2 py-1">
          <FaCalendarDay />
          <p className="font-semibold">
            {eventInfo.event.title}{" "}
            <span className="font-bold">
              ({eventInfo.event.extendedProps.location})
            </span>
          </p>
        </div>
      </>
    );
  };

  const onGetList = async () => {
    try {
      setIsLoadingAgenda(true);
      const response = await getEventList();
      if (!response.success) throw response.message;

      setIsLoadingAgenda(false);

      const eventsData = response?.data?.map((e) => ({
        id: e.id,
        title: e.title,
        start: e.start_date,
        end: e.end_date,
        location: e.location,
        invited_group_id: e.invited_group.id,
        invited_group_name: e.invited_group.name,
        user_id: e.user_id,
        color: "#0ea5e9",
      }));

      setEvents(eventsData);
    } catch (error) {
      setIsLoadingAgenda(false);
      console.log("Error on onGetList: ", error);
    }
  };

  const onGetTodoList = async () => {
    try {
      setIsLoadingTodo(true);
      const response = await getTodoList();
      if (!response.success) throw response.message;

      setIsLoadingTodo(false);
      setTodos(response?.data);
    } catch (error) {
      setIsLoadingTodo(false);
      console.log("Error on onGetTodoList: ", error);
    }
  };

  const onGetTaskList = async () => {
    try {
      setLoadingTask(true);
      const response = await getMyTasks();
      if (!response.success) throw response.message;

      setLoadingTask(false);
      setTasks(response?.data);
    } catch (error) {
      setLoadingTask(false);
      console.log("Error on onGetTaskList: ", error);
    }
  };

  useEffect(() => {
    onGetList();
    onGetTodoList();
    onGetTaskList();
  }, []);

  return (
    <div className="p-4 flex flex-wrap grid-cols-2">
      <div className="w-1/2 flex flex-col grid-cols-2">
        <div className="w-full h-[calc(100vh-50%)] p-1">
          <div className="bg-white border border-gray-200 rounded-lg shadow-3xl h-full">
            <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
              <Link to="/todo">Todo List</Link>
              <Link to="/todo">
                <FaArrowUpRightFromSquare />
              </Link>
            </div>
            <div className="p-4 flex flex-col justify-between min-h-[calc(100%-70px)] max-h-[255px] overflow-auto">
              <div className="pb-4">
                <Table
                  loading={isLoadingTodo}
                  columns={columnsTodo}
                  dataSource={todos}
                />
              </div>

              <Pagination
                currentPage={currentPageTodo}
                total={!isLoadingTodo ? todos?.length : 0}
                onPageChange={(page) => setCurrentPageTodo(page)}
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[calc(100vh-50%)] p-1">
          <div className="bg-white border border-gray-200 rounded-lg shadow-3xl h-full">
            <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
              <Link to="/projects">Task List</Link>
              <Link to="/projects">
                <FaArrowUpRightFromSquare />
              </Link>
            </div>
            <div className="p-4 flex flex-col justify-between min-h-[calc(100%-70px)] max-h-[255px] overflow-auto">
              <div className="pb-4">
                <Table
                  loading={isLoadingTask}
                  columns={columnsTask}
                  dataSource={tasks}
                />
              </div>
              <Pagination
                currentPage={currentPageTask}
                total={!isLoadingTodo ? todos?.length : 0}
                onPageChange={(page) => setCurrentPageTask(page)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-1">
        <div className="bg-white border border-gray-200 rounded-lg shadow-3xl min-h-[calc(100vh-100px)]">
          <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
            <Link to="/calendars">Agenda</Link>
            <Link to="/calendars">
              <FaArrowUpRightFromSquare />
            </Link>
          </div>
          <div className="p-4 min-h-[calc(100vh-155px)] max-h-[600px] overflow-auto">
            {isLoadingAgenda && <LoadingIcon className={"mx-auto w-10 h-10"} />}
            {!isLoadingAgenda && (
              <FullCalendar
                plugins={[timeGridPlugin, dayGridPlugin]}
                initialView="timeGridDay"
                events={events}
                eventContent={renderEventContent}
                locales={[idLocale]} // Add the locale data here
                timeZone="Asia/Jakarta"
                themeSystem="standard"
                headerToolbar={{
                  left: "title",
                  right: "",
                }}
                footerToolbar={{
                  right: "prev,today,next",
                }}
                views={{
                  timeGridDay: {
                    columnHeaderFormat: {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    },
                    slotDuration: "01:00:00", // Duration of time slots
                    slotMinTime: "06:00:00", // Start time
                    slotMaxTime: "22:00:00", // End time
                  },
                }}
                height={590}
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .fc-toolbar-title {
          font-size: 17px !important;
          color: #0369a1;
          font-weight: bold;
          text-align: left;
        }
        .fc .fc-toolbar.fc-header-toolbar {
          margin-bottom: 0.5em;
        }
        .fc-footer-toolbar fc-toolbar fc-toolbar-ltr {
          margin-top: 0.5em;
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
