import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaUserGroup, FaUsersViewfinder } from "react-icons/fa6";
import { useAppContext } from "../../context/AppContextProvider";
import { getAnalytics } from "../../services/api.service";
import CardCount from "../../components/CardCount";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userDetail } = useAppContext();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [analytics, setAnaytics] = useState({});

  const isSuperAdmin = userDetail?.levelName === "superadmin";

  useEffect(() => {
    onGetAnalytics();
  }, []);

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics('module-admin');
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setAnaytics(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  };

  if (!isSuperAdmin) {
    return navigate("/");
  }

  return (
    <div className="p-6">
      <div>
        <div className="flex gap-6">
          <CardCount
            title="Jumlah User Registrasi"
            value={analytics?.userCount || 0}
            icon={<FaUserGroup />}
            isLoading={fetchLoading}
            className={{
              container: "mb-6 from-sky-300 to-blue-600",
            }}
          />
          <CardCount
            title="Jumlah Project"
            value={analytics?.projectCount || 0}
            icon={<FaUsersViewfinder />}
            isLoading={fetchLoading}
            className={{
              container: "mb-6 from-emerald-300 to-teal-600",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
