let isRemoved = false; // Flag to track if elements have been removed
export const removeLabel = (ms = 1000) => {
  console.log("running ...");
  // Select all div elements in first layers
  const firstLayerDivs = document.body.querySelectorAll(":scope > div");

  const targetId = ["headlessui-portal-root", "root"];
  // Check if there are any div elements
  if (firstLayerDivs.length > 1) {
    firstLayerDivs.forEach((div) => {
      if (!targetId.includes(div.id)) {
        console.log("deleted...");
        isRemoved = true;
        div.remove();
      }
    });
  }
};
