import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";

import { cn } from "../utils/helper";

const BlockNoteEditorPersonal = forwardRef(
  (
    { id, name, label, icon, placeholder, errorMessage, onInputChange, className, value },
    ref
  ) => {

    const [initialContent, setInitialContent] = useState("loading");

    const handleChange = useCallback(
      (content) => {
        if (onInputChange) {
          onInputChange(JSON.stringify(content));
        }
      },
      [onInputChange]
    );

    useEffect(() => {
      if (value) {
        setInitialContent(JSON.parse(value));
      } else {
        setInitialContent();
      }
    }, [value])

    const editor = useMemo(() => {
      if (initialContent === "loading") {
        return undefined;
      }
      return BlockNoteEditor.create({ initialContent });
    }, [initialContent]);
  
    if (editor === undefined) {
      return "Loading content...";
    }

    return (
      <div className={className?.container}>
        {label && (
          <div className={cn("mb-1 text-sm text-gray-500", className?.label)}>
            {label}
          </div>
        )}
        <div className="relative rounded-md shadow-sm">
          {icon && (
            <div
              className={cn(
                "absolute text-purple text-base pointer-events-none left-4 top-1/2 -translate-y-1/2",
                { "text-red-500": errorMessage },
                className?.prefix
              )}
            >
              {icon}
            </div>
          )}
          <BlockNoteView
            ref={ref}
            id={id ? id : "block"}
            name={name ? name : "block"}
            editor={editor}
            theme={"light"}
            className={cn("border border-gray-300 rounded-lg p-2", {
              "border-red-500": errorMessage })} // Apply Tailwind border classes here
            onChange={() => handleChange(editor.document)}
          />
        </div>
        {errorMessage && (
          <p className="mt-1 -mb-5 text-sm text-red-600">{errorMessage}</p>
        )}
      </div>
    );
  }
);

export default BlockNoteEditorPersonal;
