import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FaUsersGear,
  FaChartSimple,
  FaListCheck,
  FaNoteSticky,
  FaUsersRectangle,
  FaCalendarDays,
  FaDiagramProject
} from "react-icons/fa6";
import { cn } from "../utils/helper";
import { useAppContext } from "../context/AppContextProvider";
import LogoImg from "./../assets/image/kolab-white.png";

const SuperAdminMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    license: "standard",
    link: "/admin/dashboard",
  },
  {
    title: "Users",
    icon: <FaUsersGear />,
    type: "menu",
    license: "standard",
    link: "/admin/users",
  }
];

const UserMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    license: "standard",
    link: "/dashboard",
  },
  {
    title: "Todo List",
    icon: <FaListCheck />,
    type: "menu",
    license: "standard",
    link: "/todo",
  },
  {
    title: "Notes",
    icon: <FaNoteSticky />,
    type: "menu",
    license: "standard",
    link: "/notes",
  },
  {
    title: "Teams",
    icon: <FaUsersRectangle />,
    type: "menu",
    license: "standard",
    link: "/teams",
  },
  {
    title: "Calendars",
    icon: <FaCalendarDays />,
    type: "menu",
    license: "standard",
    link: "/calendars",
  },
  {
    title: "Projects",
    icon: <FaDiagramProject />,
    type: "menu",
    license: "standard",
    link: "/projects",
  }
];

const mappingMenus = {
  'superadmin': SuperAdminMenus,
  'user': UserMenus
};

const Sider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail, setStore } = useAppContext();
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  const moduleMenus = mappingMenus[userDetail?.levelName] || [];

  useEffect(() => {
    const menuTitle = moduleMenus.find(menu => location.pathname === menu.link)?.title;
    setStore({ menuTitle });
  }, [location.pathname, moduleMenus, setStore]);

  const onClickMenu = (menu) => {
    const { title, link } = menu;
    setStore({ menuTitle: title });
    setActiveMenu(link);
    navigate(link);
  };

  const renderMenus = useMemo(() => (
    moduleMenus.map((menu, index) => (
      <li
        key={index}
        className={cn(
          "flex gap-2 p-2 m-2 rounded-md text-white list-none cursor-pointer select-none hover:bg-white/10 hover:font-bold",
          { "bg-white text-sky-700 font-bold hover:bg-white hover:text-sky-700": activeMenu === menu.link }
        )}
        onClick={() => onClickMenu(menu)}
      >
        {menu.icon}
        <span className="text-sm">{menu.title}</span>
      </li>
    ))
  ), [moduleMenus, activeMenu, onClickMenu]);

  const defaultURL = userDetail?.levelName === "superadmin" ? "/admin/dashboard" : "/dashboard";

  return (
    <aside className="min-w-[240px] min-h-screen bg-gradient-to-b from-sky-700 to-sky-500">
      <a href={defaultURL} className="flex justify-center p-6 text-center">
        <img alt="Logo" src={LogoImg} className="h-9" />
      </a>
      <hr className="mx-4 mb-6 border-t-white/20" />
      <ul>
        {renderMenus}
      </ul>
    </aside>
  );
};

export default Sider;
