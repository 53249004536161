import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPeopleCarryBox, FaPen, FaPlus } from "react-icons/fa6";

import { useAppContext } from "../../context/AppContextProvider";
import {
  upsertNote,
  deleteNote,
  getNoteList,
} from "../../services/api.service";
import { calendarFormat } from "../../utils/helper";

import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import BlockNoteEditorPersonal from "../../components/BlockNoteEditorPersonal";
import ConfirmDialog from "../../components/ConfirmDialog";

const ManageNotes = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectItem, setSelectItem] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const columns = [
    { key: "title", title: "Title" },
    {
      key: "tag",
      title: "Tag",
      render: (val) => {
        return (
          <div
            className={
              "capitalize px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5"
            }
          >
            {val}
          </div>
        );
      },
    },
    {
      key: "created_at",
      title: "Date time",
      render: (val) => calendarFormat(val, true),
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenPrintBarcode
          hiddenView
          onEdit={() => {
            setOpenForm(true);
            setSelectItem(item);
            onChange("title", item?.title);
            onChange("description", item?.description);
            onChange("tag", item?.tag);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setSelectItem(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetTodoList();
  }, []);

  const onGetTodoList = async () => {
    try {
      setIsLoading(true);
      const response = await getNoteList();
      if (!response.success) throw response.message;

      setDataSource(response?.data);
    } catch (error) {
      console.log("Error on onGetTodoList: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateUpdateTodo = async (data) => {
    try {
      setIsSubmitLoading(true);

      const request = {
        id: selectItem?.id,
        title: data?.title || selectItem?.title,
        description: data?.description || selectItem?.description,
        tag: data?.tag || selectItem?.tag,
      };

      console.log("request", request);
      const response = await upsertNote(request);
      if (!response.success) throw response.message;

      setOpenForm(false);
      onGetTodoList();
      onOpenAlert("success", response.message);
    } catch (error) {
      onOpenAlert("error", error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteNote(selectItem?.id);
      if (!response.success) throw response.message;

      setOpenConfirm(false);
      onGetTodoList();
      onOpenAlert("success", response.message);
    } catch (error) {
      onOpenAlert("error", error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onOpenForm = () => {
    if (dataSource?.length >= userDetail?.max_notes) {
      onOpenAlert("error", "Max note limit reached");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setSelectItem({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Note List</span>
          <Button
            label="Add"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={dataSource}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={currentPage}
            total={!isLoading ? dataSource?.length : 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Modal
        open={openForm}
        onOpen={setOpenForm}
        className={{ container: "p-0 max-w-3xl" }}
      >
        <form onSubmit={handleSubmit(onCreateUpdateTodo)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {selectItem?.id ? <FaPen /> : <FaPeopleCarryBox />}
            </div>
            <div className="ml-3 mr-1 text-lg">
              {selectItem?.id ? "Edit" : "Add"}
            </div>
            {selectItem?.id && (
              <h1 className="mx-1 text-sm">
                (&nbsp;{selectItem?.title}&nbsp;)
              </h1>
            )}
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Title"
                placeholder="Masukkan title"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.title?.message}
                onInputChange={(e) => onChange("title", e.target.value)}
                {...register("title", { required: "title wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="Tag"
                placeholder="Masukkan tag"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.tag?.message}
                onInputChange={(e) => onChange("tag", e.target.value)}
                {...register("tag", { required: "tag wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <BlockNoteEditorPersonal
                label="Description"
                className={{
                  label: "text-gray-700",
                }}
                errorMessage={errors?.description?.message}
                onInputChange={(e) => onChange("description", e)}
                value={selectItem?.description}
              />
            </div>

            <Button
              label="submit"
              isLoading={isSubmitLoading}
              className="w-1/3 h-10 mt-8 ml-auto"
            />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

export default ManageNotes;
