import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser, FaArrowRightFromBracket } from "react-icons/fa6";
import { Transition } from "@headlessui/react";
import { useAppContext } from "../context/AppContextProvider";
import { cn, periodLicenseValidator } from "../utils/helper";
import Logout from "../pages/authenticate/Logout";
import ChangePassword from "../pages/authenticate/ChangePassword";
import Button from "../components/Button";
import LogoImg from "./../assets/image/kolab-default.png";
import Notification from "../components/Notification";

const Header = ({ children }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { menuTitle, userDetail } = useAppContext();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [showTrialBanner, setShowTrialBanner] = useState(false);
  const [remainingTrialDays, setRemainingTrialDays] = useState(false);

  const DROPDOWN = [
    {
      title: "Change Password",
      icon: <FaUser className="text-gray-300" />,
      onClick: () => setOpenChangePassword(true),
    },
    {
      title: "Logout",
      icon: <FaArrowRightFromBracket className="text-gray-300" />,
      onClick: () => setOpenLogout(true),
    },
  ];

  useEffect(() => {
    // period license validation
    if (userDetail?.levelName === "superadmin") return setShowTrialBanner(false);

    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);
    if (rangeOfDay > 30) return setShowTrialBanner(false);

    setRemainingTrialDays(rangeOfDay || 0);
    setShowTrialBanner(true);
  }, [userDetail, setShowTrialBanner]);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  const onClickOutside = (e) => {
    if (dropdownRef.current?.contains(e.target)) return;
    setOpenDropdown(false);
  };

  const onClickDropdown = (onClick) => {
    setOpenDropdown(false);
    onClick();
  };

  const renderDropdown = () => {
    return DROPDOWN.map((item, index) => (
      <div
        key={index}
        className="flex items-center gap-2 p-2 rounded-lg cursor-pointer hover:bg-gray-100"
        onClick={() => onClickDropdown(item.onClick)}
      >
        {item.icon}
        <span>{item.title}</span>
      </div>
    ));
  };

  const renderInitials = () => {
    return userDetail?.name
      ?.split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  const defaultTitle = <img alt="" src={LogoImg} className="h-5" />;
  const defaultURL = userDetail?.levelName === "superadmin" ? "" : "/home";
  return (
    <div className="flex flex-col flex-auto">
      <nav className="z-10 grid grid-cols-[auto_1fr_auto] items-center gap-4 py-2 bg-white drop-shadow-lg px-7 max-h-20">
        <div
          className={cn("text-base lg:text-xl font-bold text-sky-700", {
            "hover:cursor-pointer hover:text-sky-900": defaultURL,
          })}
          onClick={() => {
            if (defaultURL) navigate(defaultURL);
          }}
        >
          {menuTitle || defaultTitle}
        </div>
        {showTrialBanner ? (
          <div className="relative overflow-x-hidden text-white bg-yellow-500 rounded-md">
            <div className="p-2 text-sm slide-text whitespace-nowrap">
              Masa percobaan Anda akan berakhir dalam {remainingTrialDays} hari.
              Upgrade akun Anda agar dapat kembali menikmati layanan{" "}
              <b>Kolab</b>.
            </div>
            <Button
              label="Upgrade"
              className={`
              absolute right-0 top-0 w-28 h-full py-2 ml-2 bg-yellow-600 rounded-none rounded-r-md
              hover:bg-yellow-600/75 hover:ring-yellow-600 active:focus:scale-100
            `}
              onClickBtn={() =>
                (window.location.href = "mailto:support@awandigital.id")
              }
            ></Button>
          </div>
        ) : (
          <div></div>
        )}
        <div className="relative">
          <div className="flex items-center justify-end gap-4 select-none">
            <Notification />
            <div className="w-0 h-10 border-r"></div>
            <div
              className={"cursor-pointer flex gap-4 items-center"}
              onClick={() => setOpenDropdown(!openDropdown)}
              ref={dropdownRef}
            >
              <span className="text-sm text-gray-500">{userDetail?.name}</span>
              <div className="flex items-center justify-center text-white bg-gray-400 rounded-full w-9 h-9">
                {renderInitials()}
              </div>
              <Transition
                as={Fragment}
                show={openDropdown}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute top-10 right-0 w-48 p-2 mt-2 text-base bg-white rounded-lg shadow-lg ring-1 ring-gray-300">
                  {renderDropdown()}
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </nav>
      <main className="flex-auto overflow-y-auto">{children}</main>
      <Logout open={openLogout} onOpen={setOpenLogout} />
      <ChangePassword
        open={openChangePassword}
        onOpen={setOpenChangePassword}
      />
    </div>
  );
};

export default Header;
