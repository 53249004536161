import { useCallback, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FaCalendarDay } from "react-icons/fa6";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import idLocale from "@fullcalendar/core/locales/id";

import { useAppContext } from "../../context/AppContextProvider";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputDateTime from "../../components/InputDateTime";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { LoadingIcon } from "../../assets/icon/SvgIconComponents";

import {
  upsertEvent,
  deleteEvent,
  getEventList,
  getGroups,
} from "../../services/api.service";
import { cn } from "../../utils/helper";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ManageCalendars = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const inputStartDateTimeRef = useRef();
  const inputEndDateTimeRef = useRef();

  const [events, setEvents] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectItem, setSelectItem] = useState({});
  const [activeDate, setActiveDate] = useState({});
  const [openForm, setOpenForm] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    onGetGroups();
    onGetList();
  }, []);

  const onOpenForm = (isOpen) => {
    setSelectItem({});
    setIsReadOnly(false);
    setOpenForm(isOpen);
  };

  const onGetGroups = async () => {
    try {
      const response = await getGroups();
      if (!response.success) throw response.message;
      setGroupOptions(response?.data);
    } catch (error) {
      console.log("Error on onGetList: ", error);
    }
  };

  const handleDateSelect = useCallback(
    ({ start, end }) => {
      if (events?.length >= userDetail?.max_events) {
        onOpenAlert("error", "Max event limit reached");
        return;
      }

      reset();
      clearErrors();
      setOpenForm(true);
      setActiveDate({ start, end });
    },
    [setOpenForm]
  );

  const handleEventClick = useCallback(({ event }) => {
    setSelectItem(event);
    reset();
    clearErrors();

    onChange("title", event?.title);
    onChange("location", event?.extendedProps?.location);
    onChange("invited_group_id", event?.extendedProps?.invited_group_name);
    onChange("start", moment(event?.start).format(DATE_FORMAT));
    onChange("end", moment(event?.end).format(DATE_FORMAT));
    setActiveDate({ start: new Date(event?.start), end: new Date(event?.end) });

    const isReadOnly = userDetail.userId !== event?.extendedProps?.user_id;
    setIsReadOnly(isReadOnly);
    setOpenForm(true);
  }, []);

  const onChange = useCallback(
    (field, value) => {
      setValue(field, value, { shouldValidate: true });
    },
    [setValue]
  );

  const onGetList = async () => {
    try {
      setIsLoading(true);
      const response = await getEventList();
      if (!response.success) throw response.message;

      setIsLoading(false);

      const eventsData = response?.data?.map((e) => ({
        id: e.id,
        title: e.title,
        start: e.start_date,
        end: e.end_date,
        location: e.location,
        invited_group_id: e?.invited_group?.id || null,
        invited_group_name: e?.invited_group?.name || null,
        user_id: e.user_id,
        color: "#0ea5e9",
      }));

      setEvents(eventsData);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetList: ", error);
    }
  };

  const onUpsertEvent = async (data) => {
    try {
      setIsSubmitLoading(true);
      const request = {
        id: selectItem?.id || null,
        title: data?.title || selectItem?.title,
        location: data?.location || selectItem?.extendedProps?.location,
        invited_group_id:
          data?.invited_group_id?.value ||
          selectItem?.extendedProps?.invited_group_id,
        start_date: data?.start || selectItem?.start,
        end_date: data?.end || selectItem?.end,
      };

      const response = await upsertEvent(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  };

  const onDeleteEvent = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await deleteEvent(selectItem?.id);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      onGetList();
      onOpenForm(false);
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenForm(false);
      onOpenAlert("error", error);
      console.log("Error on onDeleteEvent: ", error);
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="flex gap-1 items-center px-2 py-1">
        <FaCalendarDay />
        <p className="font-semibold">
          {eventInfo.event.title}{" "}
          <span className="font-bold">
            ({eventInfo.event.extendedProps.location})
          </span>
        </p>
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between items-center p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Calendars</span>
        </div>
        <div className="m-5 h-[800px]">
          {isLoading && <LoadingIcon className={"mx-auto w-10 h-10"} />}
          {!isLoading && (
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
              initialView="dayGridMonth"
              navLinks={true} // can click day/week names to navigate views
              dayMaxEvents={true} // allow "more" link when too many events
              weekends={true}
              events={events}
              eventContent={renderEventContent}
              locales={[idLocale]} // Add the locale data here
              timeZone="Asia/Jakarta"
              themeSystem="standard"
              selectable={true}
              select={handleDateSelect}
              eventClick={handleEventClick}
              headerToolbar={{
                left: "title",
                right: "dayGridMonth,listWeek",
              }}
              footerToolbar={{
                right: "prev,today,next",
              }}
              // businessHours={{
              //   // Specify the business hours for each day
              //   daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
              //   startTime: "09:00", // Start time for business hours
              //   endTime: "17:00", // End time for business hours
              // }}
              height="100%"
            />
          )}
        </div>
      </div>
      <Modal
        open={openForm}
        onOpen={onOpenForm}
        className={{ container: "p-0 max-w-lg" }}
      >
        <form onSubmit={handleSubmit(onUpsertEvent)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm">
              <FaCalendarDay />
            </div>
            <div className="ml-3 mr-1 text-lg">
              {selectItem?.id ? (isReadOnly ? "Detail" : "Edit") : "Add"}
            </div>
            {selectItem?.id && (
              <h1 className="mx-1 text-sm">
                (&nbsp;{selectItem?.title}&nbsp;)
              </h1>
            )}
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                disabled={isReadOnly}
                label="Title"
                placeholder="Masukkan title"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.title?.message}
                onInputChange={(e) => onChange("title", e.target.value)}
                {...register("title", { required: "title wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <InputDateTime
                disabled={isReadOnly}
                ref={inputStartDateTimeRef}
                label={"Start Date"}
                value={activeDate.start}
                onInputChange={(e) => {
                  onChange("start", moment(e).format(DATE_FORMAT));
                  setActiveDate({
                    start: moment(e).format(DATE_FORMAT),
                    end: activeDate.end,
                  });
                }}
                {...register("start")}
              />
            </div>
            <div className="mb-5">
              <InputDateTime
                disabled={isReadOnly}
                ref={inputEndDateTimeRef}
                label={"End Date"}
                value={activeDate.end}
                minDate={activeDate.start}
                onInputChange={(e) => {
                  onChange("end", moment(e).format(DATE_FORMAT));
                  setActiveDate({
                    start: activeDate.start,
                    end: moment(e).format(DATE_FORMAT),
                  });
                }}
                {...register("end")}
              />
            </div>
            <div className="mb-5">
              <Input
                disabled={isReadOnly}
                label="Location"
                placeholder="Masukkan lokasi"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.location?.message}
                onInputChange={(e) => onChange("location", e.target.value)}
                {...register("location", { required: "location wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Select
                disabled={isReadOnly}
                label="Invite Groups"
                placeholder="Pilih Group"
                options={groupOptions}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize",
                }}
                errorMessage={errors?.invited_group_id?.message}
                onInputChange={(e) => {
                  onChange("invited_group_id", e.name);
                  onChange("invited_group_id.value", e.id);
                }}
                {...register("invited_group_id")}
              />
            </div>
            {!isReadOnly && (
              <div
                className={cn("flex justify-between", {
                  "justify-end": !selectItem.id,
                })}
              >
                {selectItem?.id && (
                  <div
                    className="flex items-center justify-center gap-2 rounded-lg capitalize bg-red-600 hover:bg-red-600/75 border-none text-sm text-white font-semibold outline-none transition active:focus:scale-95 w-1/3 h-10 mt-8 hover:cursor-pointer"
                    onClick={onDeleteEvent}
                  >
                    Delete
                  </div>
                )}

                <Button
                  label="submit"
                  isLoading={isSubmitLoading}
                  className="w-1/3 h-10 mt-8"
                />
              </div>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ManageCalendars;
