import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import { DataManager, CustomDataAdaptor } from "@syncfusion/ej2-data";

import {
  BugIcon,
  TaskIcon,
  ChangeRequestIcon,
} from "../assets/icon/SvgIconComponents";

import KanbanForm from "./KanbanForm";

import "@syncfusion/ej2-base/styles/tailwind.css";
import "@syncfusion/ej2-buttons/styles/tailwind.css";
import "@syncfusion/ej2-layouts/styles/tailwind.css";
import "@syncfusion/ej2-dropdowns/styles/tailwind.css";
import "@syncfusion/ej2-inputs/styles/tailwind.css";
import "@syncfusion/ej2-navigations/styles/tailwind.css";
import "@syncfusion/ej2-popups/styles/tailwind.css";
import "@syncfusion/ej2-react-kanban/styles/tailwind.css";

import { extend } from "@syncfusion/ej2-base";

import { getLocalToken } from "../services/jwt.service";
import { useAppContext } from "../context/AppContextProvider";

const KanbanBoard = forwardRef(({ projectID }, ref) => {
  const kanbanRef = useRef(null);

  const { onOpenAlert, userDetail } = useAppContext();

  const createRequestDataManager = (url, httpMethod, option) => {
    const { data, onSuccess, onFailure } = option;

    let request;
    let serviceURL = url;
    let payloadBody = {};

    if (data) {
      const payload = JSON.parse(data);

      if (["update", "insert"].includes(payload.action)) {
        payloadBody = {
          id: payload.key,
          title: payload.value.title,
          description: payload.value.description,
          status: payload.value.status,
          priority: payload.value.priority,
          tag: payload.value.tag,
          due_date: payload.value.due_date,
          project_id: payload.value.project_id || projectID,
          user_id: payload.value.user_id,
        };
      }

      if (payload.action === "remove") {
        serviceURL = `${serviceURL}${payload.key}`;
      }
    }

    fetch(serviceURL, {
      method: httpMethod,
      headers: {
        "Content-Type": "application/json",
        Source: process.env.REACT_APP_URL,
        Name: "kantorpintar",
        Authorization: getLocalToken() ? `Bearer ${getLocalToken()}` : "",
      },
      body: Object.keys(payloadBody).length
        ? JSON.stringify(payloadBody)
        : null,
    })
      .then((response) => {
        request = extend({}, option, { httpRequest: response });
        if (response.ok) {
          return response.json();
        }

        // Handle HTTP errors
        return response.json().then((errorData) => {
          throw {
            message: errorData?.message || "Failed to fetch data",
            status: response.status,
            statusText: response.statusText,
            data: errorData,
          };
        });
      })
      .then((data) => {
        onSuccess(data, request);
      })
      .catch((error) => {
        // Handle different error types
        let errorMessage;
        if (error instanceof TypeError) {
          // Network errors or other fetch-related issues
          errorMessage = "Network error occurred. Please try again later.";
        } else if (error.status) {
          // HTTP errors
          errorMessage = `Error ${error.status}: ${error.statusText} [${error.message}]`;
        } else {
          // Other errors
          errorMessage = error.message || "An unknown error occurred.";
        }

        // Call the onFailure callback with detailed error info
        onFailure(errorMessage, error);
      });
  };

  const dataManager = new DataManager({
    adaptor: new CustomDataAdaptor({
      getData: function (option) {
        createRequestDataManager(
          `${process.env.REACT_APP_API_URL}/api/task/${projectID}`,
          "GET",
          option
        );
      },
      addRecord: function (option) {
        createRequestDataManager(
          `${process.env.REACT_APP_API_URL}/api/task/upsert`,
          "POST",
          option
        );
      },
      updateRecord: function (option) {
        createRequestDataManager(
          `${process.env.REACT_APP_API_URL}/api/task/upsert/`,
          "POST",
          option
        );
      },
      deleteRecord: function (option) {
        createRequestDataManager(
          `${process.env.REACT_APP_API_URL}/api/task/`,
          "DELETE",
          option
        );
      },
    }),
  });

  useImperativeHandle(ref, () => ({
    handleAddCard() {
      if (kanbanRef?.current) {
        kanbanRef?.current.openDialog("Add");
      }
    },
  }));

  function columnTemplate(props) {
    return (
      <p className="font-bold text-gray-600 uppercase">
        {props.headerText} - <span>{props.count}</span>
      </p>
    );
  }

  function cardTemplate(props) {
    const renderInitials = (name) => {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase();
    };

    const renderTags = (tags) => {
      return tags?.split(",").map((tag) => (
        <span
          className="font-normal tracking-normal leading-none text-xs text-sky-700 bg-sky-400 rounded-sm p-1"
          key={`tag-${tag}`}
        >
          {tag}
        </span>
      ));
    };

    const renderID = (project, id) => {
      const projectCode =
        project?.title
          ?.split(" ")
          .map((word) => word.charAt(0))
          .join("")
          .toUpperCase() || "CARD";
      return (
        <span className="font-semibold tracking-normal leading-none text-xs text-gray-900">
          {projectCode}-{id?.toString().padStart(3, "0")}
        </span>
      );
    };

    const renderType = (type) => {
      const listOfTypes = {
        task: <TaskIcon />,
        bug: <BugIcon />,
        change_request: <ChangeRequestIcon />,
      };

      const icon = listOfTypes[type] || listOfTypes["task"];

      return icon;
    };

    return (
      <div className="p-3 bg-white grid gap-3">
        <p className="font-normal tracking-normal leading-none text-gray-700">
          {props.title}
        </p>
        <div className="flex flex-wrap gap-2">{renderTags(props.tag)}</div>
        <div className="flex justify-between justify-items-center items-center">
          <div className="flex items-center gap-1">
            {renderType(props.type)}
            {renderID(props.project, props.id)}
          </div>
          {props?.assign_to?.name && (
            <span className="flex items-center justify-center text-white bg-sky-500 rounded-full w-7 h-7">
              {renderInitials(props?.assign_to?.name)}
            </span>
          )}
        </div>
      </div>
    );
  }

  function onActionFailure(e) {
    const errors = e.error.map((err) => err.error).join("\n");
    onOpenAlert("error", errors);
  }

  const formTemplate = (props) => {
    let data = {
      status: "Open",
      priority: "Low",
    };
    if (Object.keys(props).length) {
      data = props
    }

    console.log("data", data)
    return <KanbanForm payload={data} userDetail={userDetail} projectID={projectID}/>;
  };

  return (
    <KanbanComponent
      ref={kanbanRef}
      id="kanban"
      keyField={"status"}
      dataSource={dataManager}
      cardSettings={{
        template: cardTemplate,
        headerField: "id",
      }}
      dialogSettings={{ template: formTemplate, model: { width: 600 } }}
      actionFailure={onActionFailure}
      dialogOpen={(args) => {
        const { element, data, requestType } = args;

        const dialogTitle = args.element.querySelector('.e-dlg-header-content');

        if (requestType === "Edit") {
          const isOwnerTicket = data.user_id === userDetail.userId;
          if (dialogTitle) {
            dialogTitle.textContent = 'Edit Task';
          }
          
          if (!isOwnerTicket) {
            const saveButtonElement = element.querySelector(".e-dialog-edit");
            if (saveButtonElement) {
              saveButtonElement.remove();
            }

            const deleteButtonElement =
              element.querySelector(".e-dialog-delete");
            if (deleteButtonElement) {
              deleteButtonElement.remove();
            }

            if (dialogTitle) {
              dialogTitle.textContent = 'Detail Task';
            }
          }
        }

        if (requestType === "Add" && dialogTitle) {
          dialogTitle.textContent = 'Add New Task';
        }
      }}
    >
      <ColumnsDirective>
        <ColumnDirective
          headerText="To Do"
          keyField="Open"
          template={columnTemplate}
        />
        <ColumnDirective
          headerText="In Progress"
          keyField="InProgress"
          template={columnTemplate}
        />
        <ColumnDirective
          headerText="Testing"
          keyField="Testing"
          template={columnTemplate}
        />
        <ColumnDirective
          headerText="Done"
          keyField="Close"
          template={columnTemplate}
        />
      </ColumnsDirective>
    </KanbanComponent>
  );
});

export default KanbanBoard;
