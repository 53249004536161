export const userDetailCacheMap = (data = {}) => {
  return {
    userId: data?.id || data?.userId,
    email: data?.email,
    name: data?.name,
    initial: data?.initial,
    phoneNumber: data?.phone_number || data?.phoneNumber,
    photoBase64: data?.photo_base64 || data?.photoBase64,
    address: data?.address,
    province: data?.province || data?.province,
    city: data?.city || data?.city,
    levelId: data?.level_id || data?.levelId,
    levelName: data?.level_name || data?.levelName,
    licenseId: data?.license_id || data?.licenseId,
    licenseName: data?.license_name || data?.licenseName,
    periodLicense: data?.period_license || data?.periodLicense,
    ownerId: data?.owner_user_id || data?.ownerId,
    config: data?.config,
    groups: data?.groups,
    allGroups: data?.allGroups,
  }
};